@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");


  * {
    font-family: "Montserrat", sans-serif;
  }

.lang-icon-header{
  height: 20px;
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
  top: 85px;
  position: absolute;
  right: 320px;
}

.lang-icon-header:hover{
  transform: scale(1.2);
  transition: all 200ms ease;
  filter: invert(38%) sepia(78%) saturate(2100%) hue-rotate(220deg) brightness(95%) contrast(120%);
}
.titleHeader{
  color: black;
  text-decoration: none;
  font-size: 15px;
  top: 85px;
  position: absolute;
 right: 420px;
  cursor: pointer;
  transition: all 200ms ease;
}

.titleHeader:hover{
  color: #5A46AF;
  transform: scale(1.2);
  transition: all 200ms ease;

}

.titleEnglish{
  cursor: pointer;
  right: 300px;
  font-weight: bold;
  font-size: 15px;
  top: 85px;
  position: absolute;
  transition: all 200ms ease;
}

.titleEnglish:hover{
  color: #5A46AF;

  transform: scale(1.2);
  transition: all 200ms ease;

}

.cloudBlue{
  position: absolute;
  top: 0;
  right: 0;
width: 78%;
  height: 70%;
  max-height: 459px;
  z-index: -1;

}
.btn-Header {

  z-index: 10;
  margin: 15px;
  font-size: 14px;
  cursor: pointer;
  width: 160px;
  height: 31px;
  z-index: 100;
  color: white;
  background: #7b5df6;
  border-color: #7b5df6;
  border-radius: 22px;
  outline: none ;
  border-style: none ;
  transition: all 0.10s;
}


.btn-Header:hover{
  background-color: #f7c756;
  border-color: #f7c756;
  cursor: pointer;

  transition: all 0.10s;
}




  .icon-header {
    float: left;
  }

  .nav-button {
    position: absolute;
    margin-top: 65px;
    right: 60px;
  }


  .iconQuest {
    margin-top: 45px;
    margin-left: 85px;
    height: 114px;
    transition: all 1200ms ease;
  }

  .iconQuest:hover{
    transition: all 700ms ease;
    transform: rotate(-20deg);
  }


@media (max-width: 900px) {
  .containerNav{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0px;

  }
  .titleHeader{
    justify-content: center;
    display: flex;
    right: auto ;
    top: 55px;

  }

  .titleEnglish{
    justify-content: center;
    display: flex;
    right: auto ;
    top: 100px;
  }
  .icon-header {
    margin-left: -50px;
  }
  .cloudBlue{
    position: absolute;
    top: 0;
    right: 0;
    width: 55%;
    height: 30%;
    max-height: 200px;
  }
  .iconQuest {
    height: 100px;
    transition: all 1200ms ease;
  }
  .nav-button {
    right: 0px;
  }
}



@media (max-width: 500px) {
  .cloudBlue{
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;

    max-height: 200px;
  }

  .iconQuest {
    height: 80px;
  }

  .titleEnglish {
    right: 20%;
    top: 165px;
  }

  .titleHeader {
    left: 20%;
    top: 165px;
  }

  .btn-Header {
    font-size: 13px;
    width: 120px;
    height: 40px;
    margin: 10px;
  }

  .nav-button {

    margin-top: 60px;
    right: 20px;
  }

  .cloudBlue{

    max-height: 150px;
  }
}