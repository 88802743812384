.host-container{
  height: 690px;
  max-height: 690px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.link-container{
  opacity: 0.7;
position: absolute;
  bottom: 20px;
  right: 100px;

}

.copy-container{
  font-size: 15px;
  opacity: 0.7;
  position: absolute;
  bottom: 20px;
  left: 140px;
  a{
    color: black;
    text-decoration: none;
  }
}

.hr-container{
  opacity: 0.7;
  position: absolute;
  bottom: 80px;
  left: 90px;
  width: 88%;
}


.top-line{
  border-top: 1px solid black;
}

.quest-container{
  color:#f7c756 ;
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  bottom: 180px;
  left: 160px;
}

.container-question {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.img-question {
  margin-bottom: 60px;
}

.image-question-container {
  padding-left: 18%;
  padding-bottom: 20px;
}

.container-quest{
  color:#f7c756 ;
  font-size: 40px;
  font-weight: bold;
  width: 500px;
  display: flex;
  align-items: flex-end;
}


.question-container-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 80px;
}


.img-Footer{
  position: absolute;
  bottom: 390px;
  left: 410px;
}

.btn-container{
  position: absolute;
  bottom: 230px;
  right: 100px;
}

.imgLink{
  z-index: 100;
  margin-left: 30px;
  cursor: pointer;
}




.btn-Footer {
  z-index: 10;
  font-size: 15px;
  cursor: pointer;
  width: 220px;
  height: 31px;
  color: black;
  background: #f7c756;
  border-color: #f7c756;
  border-radius: 22px;
  outline: none ;
  border-style: none ;
  transition: all 0.10s;
}


.btn-Footer:hover{
  color: white;
  background-color: #1bd741;
  border-color: #1bd741;
  cursor: pointer;

  transition: all 0.10s;
}






.img-back{
  bottom: 0;
  right: 0;
  position: absolute;
  max-height: 455px;
  width: 100%;
  height: 100%;
  z-index: -1;
}


@media screen and (max-width: 1265px) {
  .quest-container {
    font-size: 30px;
    bottom: 180px;
    left: 100px;
  }

  .btn-Footer {
    margin-right: 20px;
    z-index: 10;
    font-size: 12px;
    width: 180px;
  }

  .copy-container {
    font-size: 12px;
    opacity: 0.7;
    position: absolute;
    bottom: 20px;
    left: 100px;

  }
}

@media screen and (max-width: 965px) {
  .quest-container{
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;

  }

  .container-question {
    align-items: center;
  }

  .image-question-container {
    padding: 0;
  }

  .question-container-footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 0;
  }

  .container-quest {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    width: 100%;

  }

  .btn-Footer {
    margin: 20px 0 0 0 !important;
    z-index: 10;
    font-size: 12px;
    width: 180px;
  }

  .link-container{
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0.7;
    position: absolute;
    bottom: 20px;
    right: 0px;
  }


  .copy-container{
    text-align: center;
    font-size: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 60px !important;
    left: 0;
  }

  .copy-container p {
    padding: 0 10px 15px 10px;
  }

  .btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 150px;
    right: 0px;
  }

  .hr-container{
    display: none;
  }

  .img-Footer{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 300px;
    left: 0px;
  }

}


@media screen and (max-width: 520px) {
  .quest-container{


  }

  .btn-Footer {

    margin-right: 20px;
    z-index: 10;
    font-size: 12px;
    width: 180px;
  }

  .link-container{

    right: 20px;
  }


  .copy-container{

  }

  .btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 150px;
    right: 0px;
  }



  .img-Footer{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 250px;
    left: 0px;
  }

  .img-back{
    bottom: 0;
    right: 0;
    position: absolute;
    max-height: 455px;
    width: 100%;
    height: 10%;
    z-index: -1;
  }
  .containerHost{

    max-height: 500px;
  }

}


@media screen and (max-width: 370px) {
  .quest-container{

    font-size: 18px;

  }



  .link-container{
    bottom: 20px;
    right: 20px;
  }


  .copy-container{
    text-align: center;
    font-size: 11px;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 50px;
    left: 0px;
  }

  .btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 150px;
    right: 0px;
  }

  .hr-container{
    display: none;
  }

  .img-Footer{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 300px;
    left: 0px;
  }
  .img-back{
    max-height: 300px;

  }
}