@import "../../../styles/styles.scss";

@include themify(true) {



  //==============    section 5     =====================
  

  #halfText{
    margin-left: 15px;
  }
 
  .container-membership {
    display: flex;
    flex-wrap: wrap;
   margin-top: 150px;

  }
 

  .title5 {
    color: themed(regularText);
    margin: 15px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
  }

  .title5 span{

    color: #7B5DF6;
    font: normal normal 800 30px/37px Montserrat;
    }
    


  .subtitle5 {
    margin: 1px;
    text-align: center;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    padding: 0 10px;
  }


  .text-terms {
    margin: 1px;
    text-align: center;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
  }

  .prices-title-area {
    flex: 100%;
    min-width: 300px;
  }

  .box-price img{

    display: inline-block;
  }






  //==============    section 6     =====================
  //Images styles

  .yellow-line-container{
    position: absolute;
    top: 90px;
    left: 0px;
    width: 260px;
    height: 30px;
  }

  .banner-image-container{
    position: absolute;
    top: -5px;
    left: 30px;
    width: 260px;
    height: 31px;
    
    opacity: 1;
  

  }
  .lines-image-container {
    position: absolute;
    left: -260px;
    width: 680px;
    margin-top: -120px;
    z-index: -1;
  }

  .line-blue {
    position: absolute;
    right: 0;
    height: 400px;
    z-index: -1;
  }

  .price-image{
    position: absolute;
    top: -20px;
    left: 280px;
    width: 80px;
    height: 80px;
  }

  .promo{

    position: absolute;
    color: white;
    top: 2px;
    left: 140px;
  }






  

  //Styles body

  .title6 {
    font-size: 17px;
    font-weight: bold;
    margin-top: 19px;
  }

  .title-price {
    font-size: 44px;
    font-weight: bold;
    color: themed(color-price);

    sup{
      font-size: 30px;

    }
  }


  .list-title {
    position: relative;
    font-size: 15px;
    align-items: center;

    ul {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      list-style: none;
      padding: 0;
      margin-left: 10px;
    }
    li {
      padding: 5px 0;
    }

    .item {
     display: flex;
      align-items: center;
      justify-content: center;
      
      .icon-price {
        display: flex;
        background-color: themed(color-price);
        height: 5px;
        width: 5px;
        border-radius: 20px;

      }
      & span {
        padding-left: 10px;
        
      }
    }
  }

  .button3 {
    margin: 30px;
    font-size: 15px;
    cursor: pointer;
    width: 25%;
    height: 35px;
    color: themed(background);
    background: themed(color-price);
    border-color: themed(color-price);
    border-radius: 22px;
    outline: none !important;
    border-style: none !important;
    transition: all 0.40s;
  }


  .button3:hover{
    background-color: themed(yellow_color);
    border-color: themed(yellow_color);
    cursor: pointer;
    font-weight: bold;

    transition: all 0.40s;
  }

  .prices-container {
    margin-top: 80px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    overflow: hidden;
    // @media screen and (max-width: 900px) {
    //   flex-direction: column;
    // }
  }



  .box-price {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 332px;
  
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 15px 15px;
    margin: 20px;
    transition: all 0.3s;

    & .box-price span {
      padding: 20px 0;
    }

    .box-button {
      padding: 20px 0;
    }

    .title6,
    .title-price {
      padding: 5px 0;
    }
 
  }
  
  

 

  

 

  .box-price:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    transform: scale(1.05);
    transition: all 100ms ease;
  }




  /////QUERY PHONE/////
  @media screen and (max-width: 767px) {
    .button3 {
      width: 40%;
    }


    //BOX 1
    .container-membership {
      display: flex;
      flex-wrap: wrap;

    }

    .prices-title-area {
      flex: 100%;
      //min-width: 300px;
      min-width: 100px;
    }

    .lines-image-container {
      position: absolute;

      width: 450px;
      margin-top: 80px;
      z-index: -1;
    }

    .line-blue {
      position: absolute;
    margin-top: 1000px;
      height: 300px;
      z-index: -1;
    }
    
    
    .container-membership {
      margin-top: 140%;
    }
    .subtitle5 {

      margin: 15px;
      //text-align: center;
      text-align: right;
      font-size: 15px;
      font-family: "Montserrat", sans-serif;
    }


    .banner-image-container {
      width: 90%;
      left: 10%;
    }

    .price-image {
      top: -20px;
      left: 80%;
      width: 25%;
      max-width: 80px;
      height: 16%;
      max-height: 80px;
    }

    .promo {
      position: absolute;
      top: 2px;
      left: 40%;
    }

    .title-price {
      font-size: 34px;
    }

    .title-price sup {
      font-size: 20px;
    }
    .yellow-line-container {
      position: absolute;
      width: 80%;
      height: 30px;
    }

    .box-price {;
      max-width: 332px;
      width: 100%;
      padding: 15px;
      margin: 20px 10px;
    }

    .prices-container{
      padding: 20px 0;
    }



  }
  /////QUERY TABLET//////
  @media (min-width: 768px) and (max-width: 1024px) {
    .lines-image-container {
      position: absolute;

      width: 600px;
      margin-top: 0px;
      z-index: -1;
    }

  }
  @media screen and (max-width: 1357px) {
    .container-membership {
      margin-top: 200px !important;
    }
  }

  @media screen and (max-width: 1023px) {
    .container-membership {
      margin-top: 358px !important;
    }
  }
  @media screen and (max-width: 766px) {
    .container-membership {
      margin-top: 850px !important;
    }
  }
  @media screen and (max-width: 500px) {
    .container-membership {
      margin-top: 358px !important;
    }
  }


  @media screen and (max-width: 350px) {
    .container-membership {
      margin-top: 458px !important;
    }

  }

  @media screen and (max-width: 500px) {
    .container-membership {
      margin-top: 750px !important;
    }

  }


}
