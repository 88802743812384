@import "../../../styles/styles.scss";

@include themify(true) {

  // container //
  .container-Personalize {
    max-height: 670px;
    display: flex;
    flex-wrap: wrap;
margin-top: 90px;
  }
  .flex-personalize {
    position: relative;
    flex: 2.5;
    min-width: 300px;
  }

  .flex-personalize:first-child {
    flex: 2;





  }

  .flex-personalize div {
    padding: 17px;
    max-width: 700px;
    //background-color: #3df;
  }


  .container-Personalize2 {
    display: flex;
    flex-direction: column;

    .section {
      align-items: center;
      display: flex;
      width: 90%;
      flex: 1;
      max-width: 575px;
      transition: all 0.3s;


    }

  }

  .section:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    transform: scale(1.03);

  }


  // Style //
  .title-personalize{

    color: themed(regularText);
    margin-top: -19px;
    font-size: 25px;
    margin-left:80px ;
    margin-right: 170px;
    font-weight: bolder;
    font-family: "Montserrat", sans-serif;
  }
.text-section{
  font-size: 15px;
}




  //img //

  .phone2{
    display: none;
  }

  .line-image-container {
    position: absolute;
    height: 80%;
    margin-top: 40px;
    z-index: -2;
  }

  .rings-image-container {
    border-radius: 17px;
    z-index: 3;
    margin-top: 50px;
    margin-left:210px;
    height: 65px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  .bag-image-container {
    border-radius: 17px;
    z-index: 10;
    margin-top: 0;
   height: 95px;
    margin-left:290px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  .header-app-container {
    border-radius: 25px 25px 0 0;
    margin-left: 167px;
    margin-top: 120px;
    z-index: 10;
    height: 340px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    animation: color-me-in 5s;
    position: absolute;
    left: 0;
    bottom: 0
  }





  .icon-products{
    width: 54px;
    height: 54px;
    z-index: 1;

  }

  .icon-coupons{
    width: 54px;
    height: 54px;
    z-index: 1;
  }

  .icon-orders{
    width: 54px;
    height: 54px;
    z-index: 1;
  }


/////QUERY PHONE/////
  @media screen and (max-width: 767px) {
//BOX 1
    .container-Personalize {
      margin-top: 60px;
      max-height: 100%;
    }
    .line-image-container {
      position: absolute;


      height: 200px;
      z-index: -2;

    }

    .rings-image-container {
      z-index: 3;

      margin-left:10px;
      height: 53px;

      margin-top: 20px;
    }

    .bag-image-container {
      z-index: 10;
      margin-top: 0;
      height: 80px;
      margin-left:20px;
    }


    .title-personalize{
      color: themed(regularText);
      margin-right: 120px;
      font-size: 25px;
      margin-left:0;
      font-weight: bolder;
      font-family: "Montserrat", sans-serif;
      min-width: 300px;
    }

    .text-section{
      font-size: 12px;
    }

    .container-Personalize2 {
      margin-top: 20px;
      .section {
        width: 100%;

      }

      .section-shadow {
        width: 100%;
      }


    }

    .iphone2 {

      display: none !important;
    }

    .header-app-container {
      height: 220px;
      position: absolute;
      left: 79px;
      bottom: 0;
      margin: 0;
    }


  }

  @media screen and (max-width: 600px){
    .header-app-container {
      display: none;
    }

    .phone2{
      margin-left: 81px;
      display: block;
      height: 220px;
    }

  }






  /////QUERY TABLET//////
  @media (min-width: 768px) and (max-width: 1250px) {
    .container-Personalize {
      margin-top: 60px;
      max-height: 437px;
    }

    .line-image-container {
      height: 400px;
    }

    .rings-image-container {
      height: 40px;
      margin-left: 80px;
    }

    .bag-image-container {
      height: 60px;
      margin-left: 180px;
    }

    .header-app-container {
      height: 160px;
      margin-left: 101px;
    }

    .title-personalize{
      font-size: 15px;
      min-width: 300px;

    }

    .text-section{
      font-size: 12px;
      max-width: 310px;
    }


    .icon-products{
      width: 37px;
      height: 37px;
      z-index: 1;

    }

    .icon-coupons{
      width: 37px;
      height: 37px;
      z-index: 1;
    }

    .icon-orders{
      width: 37px;
      height: 37px;
      z-index: 1;
    }

    .text-section{
      font-size: 10px;
    }

    .flex-personalize div {
      margin-top: 13px;
      padding: 5px;
      max-width: 450px;


    }
  }
  }
