
.up-container{
  width: 100%;
display: flex;
  justify-content: center;
}


.text-up-container{
  max-width: 620px;
  text-align: center;
}

.one-img-container{
  position: absolute;
  left: 50px;
  top: 250px;
}

.up-title{
  font-size: 40px;
  color: #62e4fc;
}

.up-text{
  font-size: 15px;
  padding: 1em;
}

.one-img{
  left: 180px;
  display: flex;
  position: absolute;
  margin-top: 30px;
}


/* SEARCH




  */
.iconM::before{
  content: "\2263";
  font-size: 22px;
  color: black;
  font-weight: bold;
  z-index: 200;

}

.iconM:hover{
  transform: rotate(360deg);
  transition: all 1000ms ease;
}
.search {
  margin-top: 153px;
  /*margin-top: 70px; */
  justify-content: center;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search-term {
  width: 92%;
  border: 3px solid #ffffff;

  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
}

.search-term:focus{
  color: black;
  font-weight: bold;
}

.search-button {

  width: 40px;
  height: 36px;
  border: 1px solid #ffffff;
 /* background: #00B4CC; */
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}
button{
  background: white;
  border: none;
  outline:none;
  cursor: pointer;

}

.img-search{
  transition: all 100ms ease-in;

}
.img-search:hover{

  transition: all 100ms ease-in;
  transform: scale(1.09);
}

@media only screen and (min-width: 1850px) and (max-width: 2000px) {
  .one-img-container{
    position: absolute;
    left: 300px;

  }

}

@media screen and (max-width: 1024px) {
  .one-img{
    display: none;
  }}


@media  screen and (max-width: 1022px) {
  .one-img{
   display: none;
  }

  .search-term {
    width: 100%!important;

  }

  .search {
   width: 100%!important;

    max-width: 300px!important;
  }

  .up-title{
    font-size: 38px!important;
    color: #62e4fc!important;
  }

  .up-text{
    font-size: 17px!important;
    padding: 1em!important;
  }
  .text-up-container{
    max-width: 300px!important;
    text-align: center!important;
  }

}

@media screen and (max-width: 768px) {
  .text-up-container{
    max-width: 500px;
    text-align: center;
  }
  .search {
    width: 100%;

    max-width: 500px;
  }

}

@media screen and (max-width: 600px) {
  .text-up-container {
    padding: 95px 10px 0 ;
  }
  .up-title {
    font-size: 30px;
  }
}
