$color-gray: #707070;
$error-color: #DB1212;
$warning-color: #F7C756;
$info-color: #63DDED;
$success-color: #4FD186;
$color-primary: #7B5DF6;

input[type = "radio"]{
  display: none;
}

.no-error-message {
  visibility: hidden;
  height: 0;
  max-width: 574px;
  text-align: start;
}

.error-message {
  font-size: 11px;
  width: 100%;
  color: red;
  max-width: 574px;
  text-align: start;
  height: 3px;
}

.notification {
  position: fixed;
  top: 80px;
  z-index: 300000;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 425px;
  min-height: 57px;
  box-shadow: 0 0 10px rgba(142, 147, 159, 0.15);
  border-radius: 57px;
  height: 57px;
  width: 425px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px solid;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 16px;
  animation: slide-in 0.5s forwards;

  &.error {
    background-color: rgba($error-color, 0.15);
    border-color: $error-color;
  }

  &.warning {
    background-color: rgba($warning-color, 0.15);
    border-color: $warning-color;
  }

  &.info {
    background-color: rgba($info-color, 0.15);
    border-color: $info-color;
  }

  &.success {
    background-color: rgba($success-color, 0.15);
    border-color: $success-color;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.nag-status-container {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nag-status {
  background-color: transparent;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  color: white;

  &.error {
    background-color: $error-color;
  }

  &.warning {
    background-color: $warning-color;
  }

  &.info {
    background-color: $info-color;
  }

  &.success {
    background-color: $success-color;
  }
}

.close {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.step-main-container {
  display: flex;
  padding: 0 0 158px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;

  .step-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100%;

    .step-content {
      padding: 0 32%;

      * {
        text-align: center;
      }

      .step-title {
        font-size: 25px;
        padding: 37px 0 30px 0;
      }

      .step-subtitle {
        font-size: 15px;
      }
    }

    .form {
      width: 50%;
      display: flex;
      height: 35vh;
      overflow-y: scroll;
      flex-direction: column;
      align-items: center;
      margin-top: 25px;

      .reCaptcha {
        width: 100%;
        display: flex;
        justify-content: end;
        max-width: 594px;
      }

      .question-container {
        width: 100%;
        max-width: 594px;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 20px;
        margin-bottom: 27px;
      }

      .label-check-container {
        width: 100%;
        max-width: 594px;
        height: 20px;
        margin-bottom: 27px;
        display: flex;
        justify-content: space-between;
      }

      .label-if-dont{
        display: block;
        float: left;
        width: 65%;
        margin-bottom: 27px;

        font: normal normal normal 14px/18px Montserrat;
      }

      .myCheckBoxLogo{
        display: inline-block;
        width: 20px;
        height: 20px;

      }

      .myCheckBoxTerms{
        display: inline-block;
        width: 20px;
        height: 20px;

      }

      .myCheckBox{
        display: inline-block;
        margin-left: 218px;
        width: 20px;
        height: 20px;
      }

      .label-information-Form{
        display: block;
        font: normal normal normal 14px/18px Montserrat;
      }

      .label-if-so {
        display: block;
        margin-bottom: 27px;
        width: 75%;
        font: normal normal normal 14px/18px Montserrat;
      }

      .hrefs{
        text-decoration: none;
        font-weight: 600;
        color: #7B5DF6;
      }

      .buttons_container{
        display: inline-block;
        margin-left: 4px;
      }

      .eBtnSi{ /*label*/
        position: relative;
        color: black;
        font-size: 15px;
        border: 2px solid black;
        padding: 10px 50px;
        align-items: btnSi;
        border-radius: 10px;

        outline: none;
        border: 0;

        border-radius: 5px;
        background-color: #EFEFEF;
        border: 0;
        margin-left: 3px;
      }

      .eBtnNo {/*label*/
        position: relative;
        color: black;
        font-size: 15px;

        padding: 10px 50px;
        align-items: center;


        outline: none;
        border: 0;

        border-radius: 5px;
        background-color: #EFEFEF;
        border: 0;
        margin-left: 3px;
      }

      input[type = "radio"]:checked + .eBtnSi{
        background-color: black;
        color: white;
      }

      input[type = "radio"]:checked + .eBtnNo{
        background-color: black;
        color:white;
      }

      .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: 574px;
        padding-right: 20px;
        gap: 36px;

        div {
          width: 100%;
          display: flex;
          flex-direction: column;

          .field-two, .select-information-form {
            width: 100%;
            padding: 10px;
            outline: none;
            border: 0;
            margin-bottom: 17px;

            background: #EFEFEF 0% 0% no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
          }

          .select-information-form {
            width: 107%;
          }
        }
      }

      .field {
        width: 100%;
        max-width: 574px;
        padding: 10px;
        outline: none;
        border: 0;
        margin-bottom: 17px;

        background: #EFEFEF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
      }

      span {
        position: relative;
        top: -17px;
      }

      .field-error, .field-error-two, .select-information-error {
        width: 100%;
        max-width: 574px;
        padding: 10px;
        outline: none;
        border-radius: 5px;
        background-color: #EFEFEF;
        margin-bottom: 17px;

        border: solid red 1px;

        &.verifying {
          max-width: 465px;
          border-radius: 5px 0 0 5px;
        }
      }
    }

    .step-buttons {
      position: fixed;
      bottom: 46px;
      width: 45vw;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .button {
        padding: 6px 76px;
        border-radius: 20px;
        border: none;
        cursor: pointer;

        &.back {
          background-color: white;

          &:hover {
            background-color: darken(white, 10%);
          }

          &:focus {
            background-color: darken(white, 10%);
          }

          &:active {
            background-color: lighten(white, 5%);
          }
        }

        &.next {
          background-color: $warning-color;

          &:hover {
            background-color: darken($warning-color, 5%);
          }

          &:focus {
            background-color: darken($warning-color, 5%);
          }

          &:active {
            background-color: lighten($warning-color, 5%);
          }
        }
      }
    }
  }
}