$midnight: #2c3e50;
$clouds: #ecf0f1;
// General

.accordion-container{
  margin-top: 120px;
  width: 100%;
  display: flex;
  justify-content: center;

}

.accordion-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
// Layout
.row {

  max-width: 63%;
  display:flex;
  .col {
    flex:1;
    &:last-child {

      margin-left: 2em;
    }
  }
}
/* Accordion styles */
.tabs {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

}
.Effect:hover{

  border: #75e7fc 0.1px solid;

}
.tab {
  width: 100%;

  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 2em;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {

    }
    &::after {
      content: "\276F";
      width: 0em;
      height: 1em;
      text-align: center;
      transition: all .10s;
      margin-left: 15px;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 2em;
    color: $midnight;
    background: white;
    transition: all .10s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 2em;
    font-size: 0.75em;
    background: $midnight;
    cursor: pointer;
    &:hover {
      background: darken($midnight, 10%);
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    border-color: aqua;

    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 2em;
  }
}


@media  screen and (max-width: 1022px) {

  .accordion-container{
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

  }
  .row {
    max-width: 100%;
    display:flex;
    .col {
      flex:1;
      &:last-child {
        max-width: 450px;
        margin-left: 0;
      }
    }
    .tabs {
      width: 98%;
    }
  }
}


@media  screen and (max-width: 600px) {

  .row {
    .col {
      &:last-child {
        max-width: 300px;
      }
    }
  }
}