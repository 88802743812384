@import "./theme/styles/styles.scss";

html {
  scroll-behavior: smooth;
  overflow-x: hidden;

}

.containerHidden{
  overflow: hidden;
}

.relative{
  position: relative;
}








@include themify(true) {
   

}