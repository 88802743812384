
@import "../../../styles/styles.scss";

@include themify(true) {

  //container
  .ContainerAbout {
    text-align: -webkit-center;
    display: flex;
    flex-wrap: wrap;
    background-color: themed(banner-info);
    margin-top: 21px;
    height: 830px;
  }

  .flex-about{
    flex: 1;
    min-width: 200px;
    margin-top: 65px;

  }





  //style text

  .titleAbout{
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: themed(color-info);
    text-align: center;
    margin: 15px;
    max-width: 536px;

  }

  .subtitleAbout{
    margin-top: 43px;
    text-align: center;
    max-width: 585px;
    color: #BF8D17;
    padding: 10px;
    font-size: 15px;
    text-align: center;
    line-height: 1.5;
  }

  //img
  .grupo333{
    width: 100%;
    max-width: 1280px
  }

  //QUERY PHONE//
  @media screen and (max-width: 600px) {
    .ContainerAbout {
      height: 630px;
    }

    .titleAbout{
      margin: 25px;
    }

    .subtitleAbout{
      margin: 25px;

    }

  }

}
