@import "../../../styles/styles.scss";

@include themify(true) {


  //container
  .containerManages {
    display: flex;
    flex-wrap: wrap;
    background: themed(opacity-purple);
    margin-top: -4px;
    max-height: 770px;


  }

  input::placeholder {
    color: black;
    font-size: 15px;
    font-weight: 500;
  }

  .flex-manages {
  position: relative;
    flex: 1;
    min-width: 250px;
    max-width: 100%;

  }

  .flex-manages div {
    //text-align: justify;

    padding: 30px;
  }


  //Style
  .Title-management{
    max-width: 460px;
    color: themed(purple-hard);
    font-size: 25px;
    font-weight: bold;
    margin-top: 85px;
    margin-left: 48px;
  }

  .SubTitle-management{
    max-width: 500px;
    color: themed(purple-hard);
    font-size: 15px;
    margin-left: 48px;
    line-height: 1.5;
    margin-top: 30px;
  }

  .SubTitle-management2{
    max-width: 512px;
    color: themed(purple-hard);
    font-size: 15px;
    margin-left: 48px;
    line-height: 1.5;

  }






  //IMG

  .background-image{
    position: absolute;
    left: 0;

    height: 85%;
    z-index: 0;
  }


  .second-part-container {
    margin-top: 0px;
    margin-left: 167px;
    z-index: 2;
    height: 340px;
   position: relative;

  }

  .item-buy-image {
    z-index: 3;
    margin-left:400px;
    margin-top: -32px;
    height: 289px;
    position: relative;
  }

  .img-button {
    position: absolute;
    bottom: 273px;
    left: 115px;
    z-index: 4;

    height: 61px;
  }
  .first-part-image {
    position: absolute;
    z-index: 4;
    margin-left: 155px;
    margin-top: -37px;
    height: 202px;
    left: 0;
    bottom: 0;

  }




  /////QUERY TABLET//////
  @media (min-width: 600px) and (max-width: 1250px) {
    .background-image{
      position: absolute;
      left: 0;
      top: 0;
      height: 80%;
      z-index: 0;
    }



    .second-part-container {
      margin-left: 100px;
     height: 160px;
    }
    .item-buy-image {
      margin-left:180px;
      margin-top:-22px;
     height: 130px;
    }

    .img-button {
      z-index: 3;
      margin-left:-60px;
      height: 25px;
      bottom: 140px;
    }

    .first-part-image {
      margin-left:50px;
      margin-top:-25px;
      height: 100px;
    }


    .Title-management{
      color: themed(purple-hard);
      font-size: 21px;
      font-weight: bold;
      margin-left: 10px;
      margin-top: 23px;
      max-width: 350px!important;

    }

    .SubTitle-management{
      color: themed(purple-hard);
      font-size: 11px;
      margin-left: 10px;
      max-width: 340px !important;
    }

    .SubTitle-management2{
      color: themed(purple-hard);
      font-size: 11px;
      margin-left: 10px;
      max-width: 340px !important;
    }
    .flex-manages div {

      padding: 8px;

    }
    .containerManages {
      max-height: max-content;
    }

    .phone2 {
      display: none !important;
    }
    .header-app-container {
      display: block;
    }


  }




  /////QUERY PHONE/////
  @media screen and (max-width: 600px) {
    //BOX 1

    .background-image{
      position: absolute;
      left: 0;

      height: 70%;
      z-index: 0;
    }

    .containerManages {

      max-height: 100%;
      flex-direction: column !important;


    }


    .second-part-container {
      margin-top: 0px;
      margin-left: 17px;
      z-index: 1;

      height: 220px;
    }

    .item-buy-image {
      z-index: 2;
      margin-left:100px;
      margin-top:-15px;
      height: 200px;
      position: inherit;
    }
    .first-part-image {
      display: none;

    }
    .img-button {

      z-index: 1;
      margin-left:-100px;
      height: 40px;
      bottom: -52px;

    }

    .Title-management{
      color: themed(purple-hard);
      font-size: 25px;
      font-weight: bold;
      margin-left: 10px;
    }

    .SubTitle-management{
      color: themed(purple-hard);
      font-size: 15px !important;
      margin-left: 10px;
    }

    .SubTitle-management2{
      color: themed(purple-hard);
      font-size: 15px;
      margin-left: 10px;
    }
  }


  //new
  @media screen and (max-width: 766px) {


    .containerManages {

      max-height: max-content;


    }


    .second-part-container {
      margin-left: 100px;
      height: 220px;
    }
    .item-buy-image {
      margin-left:180px;
      margin-top:-22px;
      height: 200px;
    }

    .img-button {
      z-index: 3;
      margin-left:-100px;
      height: 40px;
      bottom: 90px;
    }

    .first-part-image {
     display: none;
    }


    .Title-management{
      color: themed(purple-hard);
      font-size: 21px;
      font-weight: bold;
      margin-left: 10px;
      margin-top: 50px;
      max-width: 350px!important;

    }

    .SubTitle-management{
      color: themed(purple-hard);
      font-size: 11px;
      margin-left: 10px;
      max-width: 340px !important;
    }

    .flex-manages div {

      padding: 15px;

    }

  }


  @media screen and (max-width: 766px) {
    .containerManages {

      max-height: max-content;


    }


    .second-part-container {
      margin-left: 80px;
      height: 220px;
    }
    .item-buy-image {
      margin-left:160px;
      margin-top:-22px;
      height: 150px;
    }

    .img-button {
      z-index: 3;
      margin-left:-100px;
      height: 30px;
      bottom: 170px;
    }

    .first-part-image {
      display: none;
    }


    .Title-management{
      color: themed(purple-hard);
      font-size: 21px;
      font-weight: bold;
      margin-left: 10px;
      margin-top: 50px;
      max-width: 350px!important;

    }

    .SubTitle-management{
      color: themed(purple-hard);
      font-size: 11px;
      margin-left: 10px;
      max-width: 340px !important;
    }

    .flex-manages div {

      padding: 15px;

    }


    }



  }
