$color-accent: #EA40A6;
$color-primary: #7B5DF6;

.main-container {
  display: flex;
  padding: 42px 0 158px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;

    .content {
      margin-bottom: 17vh;

      * {
        text-align: center;
      }

      .title {
        font-size: 40px;
        padding: 40px 0;
        color: $color-accent;
      }

      .subtitle {
        font-size: 15px;
      }
    }

    .button {
      background-color: $color-primary;
      padding: 14px 76px;
      color: white;
      border-radius: 24px;
    }
  }
}