$color-gray: #707070;
$error-color: #DB1212;
$warning-color: #F7C756;
$info-color: #63DDED;
$success-color: #4FD186;
$background-purple: #7B5DF6FF;
$button-color: #F7C756FF;
$white : #FFFF;

.no-error-message {
  visibility: hidden;
  height: 0;
  max-width: 574px;
  text-align: start;
}

.error-message {
  font-size: 11px;
  width: 100%;
  color: red;
  max-width: 574px;
  text-align: start;
  height: 3px;
}

.notification {
  position: fixed;
  top: 80px;
  z-index: 300000;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 425px;
  min-height: 57px;
  box-shadow: 0 0 10px rgba(142, 147, 159, 0.15);
  border-radius: 57px;
  height: 57px;
  width: 425px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px solid;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 16px;
  animation: slide-in 0.5s forwards;

  &.error {
    background-color: rgba($error-color, 0.15);
    border-color: $error-color;
  }

  &.warning {
    background-color: rgba($warning-color, 0.15);
    border-color: $warning-color;
  }

  &.info {
    background-color: rgba($info-color, 0.15);
    border-color: $info-color;
  }

  &.success {
    background-color: rgba($success-color, 0.15);
    border-color: $success-color;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.nag-status-container {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nag-status {
  background-color: transparent;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  color: $white;

  &.error {
    background-color: $error-color;
  }

  &.warning {
    background-color: $warning-color;
  }

  &.info {
    background-color: $info-color;
  }

  &.success {
    background-color: $success-color;
  }
}

/* Modal container */
.all-container {
  max-width: 399px;
  max-height: 329px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 35px 40px 22px 40px;
  border: 1px solid $color-gray;
  border-radius: 27px;
  background: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  animation: fadeIn 0.3s ease-out;
}

/* Animation for modal appearance */
@keyframes fadeIn {
  0% {
    transform: translate(-50%, -60%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.first-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-container {
  width: 100%;
  height: 100vh;
  position: static;
}

.btn-cancel-container {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.indications-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.button-container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

#shopping-cart {
  width: 64px;
  height: 55px;
}

.cancel {
  font-size: 20px;
  font-weight: bold;
  background: transparent;
  color: $background-purple;
  cursor: pointer;
}

.title-indications {
  width: 100%;
  height: 27px;
  font-weight: bold;
  text-align: left;
  margin-top: 25px;
  color: $background-purple;
}

.p1 {
  width: 100%;
  height: 27px;
  margin-top: 25px;
}

.p2 {
  width: 100%;
  height: 76px;
  margin-top: 45px;
}

.button-close-modal {
  width: 80%;
  height: 31px;
  font-size: 15px;
  cursor: pointer;
  color: $white;
  border-color: $button-color;
  background:  $button-color;
  border-radius: 22px;
  outline: none;
  border-style: none;
  transition: all 0.10s;
}

.button-close-modal:hover {
  background: $background-purple;
  border-color: $background-purple;
  color: $white;
}


input:focus, button:focus {
  border: 2px solid $white;
  outline: none;
}

.close {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.step-main-container {
  display: flex;
  padding: 0 0 158px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;

  .step-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100%;

    .step-content {
      padding: 0 32%;

      * {
        text-align: center;
      }

      .step-title {
        font-size: 25px;
        padding: 37px 0 30px 0;
      }

      .step-subtitle {
        font-size: 15px;
      }
    }

    .stepone-form {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 25px;

      .field {
        width: 100%;
        max-width: 574px;
        padding: 10px;
        outline: none;
        border: 0;

        background: #EFEFEF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;

        &.verifying {
          max-width: 465px;
          border-radius: 10px 0 0 10px;
        }
      }

      .field-error {
        width: 100%;
        max-width: 574px;
        padding: 10px;
        outline: none;
        border-radius: 5px;
        background-color: #EFEFEF;

        border: solid red 1px;

        &.verifying {
          max-width: 465px;
          border-radius: 5px 0 0 5px;
        }
      }

      .verifying-field-group {
        margin-top: 17px;
        display: flex;
        flex-direction: row;
        height: 36px;
        width: 100%;
        justify-content: center;

        .verifying-field {
          width: 100%;
          max-width: 465px;
          padding: 10px;
          outline: none;
          border: 0;

          background: #EFEFEF 0% 0% no-repeat padding-box;
          border-radius: 10px 0 0 10px;
          opacity: 1;
        }

        .verifying-button {
          cursor: pointer;
          background-color: $color-gray;
          color: white;
          width: 109px;
          text-align: center;
          border-radius: 0 10px 10px 0;

          &:hover {
            background-color: darken($color-gray, 10%);
          }

          &:focus {
            background-color: darken($color-gray, 10%);
          }

          &:active {
            background-color: lighten($color-gray, 5%);
          }
        }
      }
    }

    .step-buttons {
      position: fixed;
      bottom: 46px;
      width: 45vw;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .button {
        padding: 6px 76px;
        border-radius: 20px;
        border: none;
        cursor: pointer;

        &.back {
          background-color: white;

          &:hover {
            background-color: darken(white, 10%);
          }

          &:focus {
            background-color: darken(white, 10%);
          }

          &:active {
            background-color: lighten(white, 5%);
          }
        }

        &.next {
          background-color: $warning-color;

          &:hover {
            background-color: darken($warning-color, 5%);
          }

          &:focus {
            background-color: darken($warning-color, 5%);
          }

          &:active {
            background-color: lighten($warning-color, 5%);
          }
        }
      }
    }
  }
}