$base_color       : #2b2b2b;
$primary_color    : #3159a6;
$secondary_color  : #043494;
$tint_color       : #003192;
$font_color       : #e5e4e4;
$red_color        : #ed2020;
$border_color     : #757575;
$border_alt       : #353838;
$icons_color      : #c8c8c8;
$layout_bg        : #202020;
$logo_bg          : #202020;
$gray_font        : #737981;
$sidebar          : #111622;
$flat-orange      : #F18700;
$success          : #00C121;
$title            : #75e7fc;
$background-purple: #7b5df6;
$text-color-price : #7B5DF6;
$background-banner: #f7c7564d;
$text-color-banner: #BF8D17;
$button-submit    : #75E7FC;
$text-purple-hard : #5A46AF;
$text-color-button: #f7c756;
$background-opacity:#7b5df64d;
$Whatsapp-color    :#1bd741;

$dark:  (
  primaryColor: $primary_color,
  backgroundLayout: $layout_bg,
  background: $base_color,
  borderFocus: white,
  border: $border_color,
  borderSidebar: $border_alt,
  regularText: $font_color,
  button: $primary_color,
  highlight: $tint_color,
  color-price: $text-color-price,
  danger: $red_color,
  iconColor: $icons_color,
  dashboardBanner: $secondary_color,
  dashboardBannerText: white,
  logo-bg: $logo_bg,
  gray_font: $gray_font,
  sidebar-color: $sidebar,
  orange_color: $flat-orange,
  success: $success,
  title:$title,
  background-purple:$background-purple,
  banner-info: $background-banner,
  color-info: $text-color-banner,
  pricing-button-submit: $button-submit,
  purple-hard:$text-purple-hard,
  yellow_color:$text-color-button,
opacity-purple:$background-opacity,
whatsapp-color:$Whatsapp-color
)
