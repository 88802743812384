




.relative{
  position: relative;
}


#miheader{
  background-color: purple;
}

#body{
  background-color: red;
}

#acordeon{
  background-color: green;
}


#mifooter{
  background-color: black;
}



