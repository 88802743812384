@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");
@import "../../styles/styles.scss";
@include themify(true) {

  * {

    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;

  }

  body {

  }

  header {
    
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

  }

  .contenedor {
    
    width: 98%;
    background: themed(background);
    z-index: 100;

  }

  .float-icon {
    float: left;
  }
  .float-nav {
    float: right;
    margin-top: 75px;
    margin-right: 10%;
  }

  header .contenedor {
    display: table;
  }

  #menu-bar {
    display: none;
    transition: opacity ease 0.4s;//
  }

  header label {
    float: right;
    margin: 6px 0;
    cursor: pointer;

  }

  .menu {
    position: absolute;
    top: 100px;
    left: 0;
    width: 178px;
    height: 100vh;
    transform: translateY(-100%);
    align-items: center;

  }

  .menu a {
    font-weight: 500;
    display: block;
    color: themed(regularText);
    text-decoration: none;
    border-bottom: 1px solid themed(regularText);
    transition: all 200ms ease;
  }



  .menu a:hover{
    color: themed(purple-hard);
    transform: scale(1.2);
    transition: all 200ms ease;


  }

  .menu button {
    font-weight: 500;
    display: block;
    color: themed(regularText);
    text-decoration: none;
    border-bottom: 1px solid themed(regularText);
    
  }


  #menu-bar:checked ~ .menu {
    transform: translateY(60%);

  }

  .register-button{
    width: 120px;
    height: 30px;
    font-size: 14px;
    border-radius: 20px;
    align-content: center;
    font-weight: 100 !important;
    background-color: themed(background-purple);
    color: themed(dashboardBannerText) !important;
  }

  .button , .register-button:hover{
    transform: scale(1.1);
    font-weight: bold;
    transition: all 200ms ease;
  }

  .logging-button{
    width: 90px;
    font-size: 14px;
    padding-left: 10px;
    background-color: unset;
    text-align: left !important;
    color: themed(background-purple) !important;
  }

  .button , .logging-button:hover{
    transform: scale(1.2);
    font-weight: bold;
    transition: all 200ms ease;
  }

  .lang-icon{
    height: 20px;
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
  }

  .lang-icon:hover{
    transform: scale(1.2);
    transition: all 200ms ease;
    filter: invert(38%) sepia(78%) saturate(2100%) hue-rotate(220deg) brightness(95%) contrast(120%);
  }

  @media (min-width: 1024px) {

    .menu {
      justify-content: space-evenly;
      width: 100%;
      transform: translateY(450%);
      padding-left: 155px;
      letter-spacing: 0.1px;
      display: flex;
      font-size: 15px;
      height: 30px;
      position: absolute;
      z-index: 10;
      margin-top: -150px;
    }

    header label {
      display: none;
      // background: rgba(231, 114, 114, 0.9);
    }
    .menu a {
      border: none;
      text-align: center;
    }
    .menu button {
      border: none;
      text-align: center;
    }
  }
  .icon {
    margin-top: 45px;
    margin-left: 85px;
    width: 114px;
    height: 114px;
    transition: all 1200ms ease;
  }

  .icon:hover{
    transition: all 700ms ease;
    transform: rotate(-20deg);
  }

.iconM::before{
    content: "\2263";
    font-size: 50px;
    color: themed(regular-text);
  font-weight: bold;
  z-index: 200;
  }

  .iconM:hover{
    transform: rotate(360deg);
    transition: all 1000ms ease;
  }



  .english {
    cursor: pointer;
    outline: none ;
    border: none;
    background: none;
    font-weight: 300;
    font-size: 15px;
    margin-right: 1rem;
    letter-spacing: -1px;
    color: themed(regularText);
    transition: all 200ms ease;

  }

  .button , .english:hover{
    color: themed(purple-hard);

    transform: scale(1.2);
    transition: all 200ms ease;

  }



  /////QUERY PHONE//////
  @media screen and (max-width: 1023px) {
    .icon {
      margin-left: 70px;
     width: 90px;
    height: 90px;
    }

    .float-icon {
      margin-left: -50px;

    }

    .menu {
      top: -20px;
      left: 0;
      height: auto;
      width: 100%;
      opacity: 0; //
      transition: opacity ease-in 0.4s;//
      background-color: transparent; //
      transform: translateY(-100%);  //
      a:hover {
        color: themed(purple-hard);
        transform: scale(1.0);
      }

      .button , .english:hover{
        transform: scale(1.0);
      }
    }

    .menu a {

      font-weight: 500;
      display: block;
      color:themed(regularText);
      height: 50px;
      text-decoration: none;
      margin-top: 0px;
      padding: 31px;
      border-bottom: 1px solid #212E3E22;

    }

    .menu button {

      font-weight: 500;
      display: block;
      color:themed(regularText);
      height: 50px;
      text-decoration: none;
      margin-top: 0px;
      padding: 31px;
      border-bottom: 1px solid #212E3E22;

    }


    .english {
      margin-left: 0px;
      letter-spacing: 0px;
      color: themed(regularText);

    }



    header {
      width: 100%;
      height: 162px;
      position: inherit;
      top: 0;
      left: 0;
      z-index: 100;
      background: themed(background);

    }
    #menu-bar:checked ~ .menu {
      opacity: 1; //
      transform: translateY(53%);
      z-index: 1; //
      background-color:themed(background);   //

    }
  }





}
