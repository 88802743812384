@import "../../../styles/styles.scss";

@include themify(true) {

  /////CONTAINERS////
  .container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .slider-container-wrapper {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .in-flex-box1 {

    flex: 1;
    min-width: 300px;
    padding-left: 105px;
    height: 658px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .in-flex-box2 {

    flex: 1;
    min-width: 300px;
  }
  .container-banner{
    height:750px;
    display: flex;
    align-items:flex-end ;
  }

  /////STYLES/////
  .title{
    max-width: 440px;
    margin-top: 50px;
    color: themed(color-price);
    font-size: 40px;
    font-weight: bolder;
    font-family: "Montserrat", sans-serif;
  }

  .subtitle {
    margin-top: 35px;
    font-size: 25px;
    font-family: "Montserrat", sans-serif;
    max-width: 400px;
  }

  .div-class1{
    width: 81px;
    height: 6px;
    margin-top: 20px;
    background:themed(color-price);
    border-color: themed(color-price);
    border-radius:22px;

  }

  .div-class2{
    width: 24px;
    height: 6px;
    top: 20px;
    margin-left: 100px;
    background:themed(color-price);
    border-color: themed(color-price);
    border-radius:22px;
    opacity: 0.7;

  }

  .div-class3{
    width: 24px;
    height: 6px;
    top: 20px;
    margin-left: 35px;
    background:themed(color-price);
    border-color: themed(color-price);
    border-radius:22px;

  }

  /////IMAGES/////


  .img-case {
    margin-top: -48px;
    margin-left: -50px;
    z-index: 10;
    height: 577px;
  }

  .img-ContenentPhone {
    margin-top: 16px;
    margin-left: 16px;
    z-index: 200;
    height: 545px;
    border-radius: 29px;
  }


  .img-banner {
    z-index: -1;
    position: absolute;
    top: 23px;
    right: 0;
    min-width: 200px;
    max-width: 650px;
    width: 48%;
  }




















  //SLIDERRRRRRRRRRRRRRRR







  /*==================
      Base Styles
  ===================*/

  * {
    box-sizing: border-box;
  }



  input[type="radio"] {
    display: none;
  }

  .containerSli {
    position: relative;
    height: 419px;
    width: 500px;
    padding: 2em;

  }

  .slide-wrapper {
    position: relative;
    margin: 0 auto;
    padding: 3em;
    width: 450px;
    height: 360px;
    border-radius: 20px;
    overflow: hidden;
  }

  //
  .wrapper-phone{
    z-index: -1;
    width: 285px;
    display: flex;
    margin-top: -474px;
    margin-left: 49vw;
    min-height: 577px;
    background-color: white;
    border-radius: 45px;
  }


  /*==================
      Slides
  ===================*/

  #slide-role {
    background: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 600%;
    height: 100%;
    z-index: -1;
    transition: left .5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    /* flex */
    display: flex;

  }




  .slide {

    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }





  /*===================
      BUTTONS
  ====================*/

  .btn,
  .btn::after {

    border-radius: 20px;
    transition: all .5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .btn {

    position: absolute;
    bottom: 20px;
    width: 60px;
    height: 20px;
    cursor: pointer;
    transform: scale(1.1);
    z-index: 1000;
  }

  .btn:nth-of-type(1) {
    left: 35px;

  }

  .btn:nth-of-type(2) {
    left: 80px;
  }

  .btn:nth-of-type(3) {
    left: 125px;
  }

  .btn:nth-of-type(4) {
    left: 170px;
  }

  .btn:nth-of-type(5) {
    left: 215px;
  }

  .btn:nth-of-type(6) {
    left: 260px;
  }



  .btn:hover {
    transform: scale(1.4);

  }

  /* buttons Atom! */

  .btn::after {

    content: "";
    display: inline-block;
    background:themed(slider-color );
    border-color: themed(opacity-purple);
    width: 40px;
    height: 12px;
    margin: 3px 3px;
    transform: scale(.5, .5);


  }

  /*====================
          L O G I C
   ===================*/

  /* slide logic */
  input[type="radio"]:checked#slide-1-trigger~div.slide-wrapper>div#slide-role {
    left: 0%;

  }

  input[type="radio"]:checked#slide-2-trigger~div.slide-wrapper>div#slide-role {
    left: -100%;
  }

  input[type="radio"]:checked#slide-3-trigger~div.slide-wrapper>div#slide-role {
    left: -200%;

  }

  input[type="radio"]:checked#slide-4-trigger~div.slide-wrapper>div#slide-role {
    left: -300%;

  }
  input[type="radio"]:checked#slide-5-trigger~div.slide-wrapper>div#slide-role {
    left: -400%;

  }

  input[type="radio"]:checked#slide-6-trigger~div.slide-wrapper>div#slide-role {
    left: -500%;

  }



  /* button logic */

  input[type="radio"]:checked+label.btn {
    transform: scale(0.9);
  }

  input[type="radio"]:checked+label.btn::after {
    transform: scale(1.4, 1.1);
    background:themed(color-price);
  }




  ///END SLIDERRRRRRRRRR
  @media only screen and (min-width: 1850px) and (max-width: 2000px) {
    .wrapper-phone{


      margin-left: 1200px;

    }

  }


  @media screen and (max-width: 1100px) {
    .wrapper-phone{
      z-index: -1;

      margin-left: 540px;

    }

  }


  /////QUERY TABLET//////
  @media (min-width: 768px) and (max-width: 1023px) {
    //SLIDER PHONE


    //SLIDER REAL
    .containerSli {
      height: 310px!important;
      width: 280px!important;

    }

    .slide-wrapper {

      width: 280px!important;
      height: 340px!important;
    }
    .btn {


      bottom: -30px!important;

    }

    .btn::after {

      width: 30px!important;


    }

    .btn:nth-of-type(1) {
      left: 30px!important;
    }

    .btn:nth-of-type(2) {
      left: 70px!important;
    }

    .btn:nth-of-type(3) {
      left: 110px!important;
    }

    .btn:nth-of-type(4) {
      left: 150px!important;
    }
    .btn:nth-of-type(5) {
      left: 190px!important;
    }

    .btn:nth-of-type(6) {
      left: 230px!important;
    }





    //SLIDER
    .btn-menu{
      text-align: center;
      margin: 4px;
      padding-left: 35px;
    }

    .containerSlider{
      width: 437px;
      max-width: 279px;
      height: 307px;
    }


    ul.slider{
      position: relative;
      width: 293px;
      height: 251px;
    }



    //box
    .in-flex-box1 {
      z-index: 0;
      min-width: 200px;
      padding-left: 80px;
      height: 450px;
    }

    .in-flex-box2 {

      min-width: 200px;
    }
    .container-banner{
      height:650px;
      display: flex;
      align-items:flex-end ;
    }
    .img-phone {
      z-index: -2;
      width: 270px;
      height: 500px;
    }

    .title{
      font-size: 25px;
    }

    .subtitle{
      font-size: 23px;
    }

    .img-banner {
      top: 170px;
      width: 90%;
      max-width: 370px;
      z-index: -3;
    }

    //SLIDER
    .wrapper-phone{
      z-index: -1;
      width: 238px!important;
      display: flex;
      margin-top: -474px;
      margin-left: 340px;
      min-height: 577px;
      background-color: white;
      border-radius: 45px;
    }

    .img-case {
      margin-top: -48px;
      margin-left: -48px;
      z-index: 10;
      height: 477px;
    }

    .img-ContenentPhone {
      margin-top: 16px;
      margin-left: 15px;
      z-index: 200;
      height: 445px;
      border-radius: 20px;
    }

  }




  /////QUERY PHONE//////
  @media screen and (max-width: 767px) {



    //SLIDER REAL
    .containerSli {
      height: 310px!important;
      width: 280px!important;

    }

    .slide-wrapper {

      width: 280px!important;
      height: 300px!important;
    }
    .btn {
      bottom: 25px!important;
    }
    .btn::after {
      width: 25px!important;
    }


    .btn:nth-of-type(1) {
      left: 20px!important;
    }

    .btn:nth-of-type(2) {
      left: 50px!important;
    }

    .btn:nth-of-type(3) {
      left: 80px!important;
    }

    .btn:nth-of-type(4) {
      left: 110px!important;
    }

    .btn:nth-of-type(5) {
      left: 140px!important;
    }

    .btn:nth-of-type(6) {
      left: 170px!important;
    }








    //SLIDER
    .btn-menu{
      text-align: center;
      margin: -10px;
      padding-left: 35px;
    }

    .containerSlider{
      width: 437px;
      max-width: 279px;
      height: 219px;
    }


    ul.slider{
      position: relative;
      width: 293px;
      height: 193px;
    }


    .img-banner {
      top: 350px !important;
      width: 90%;
      max-width: 370px;
      z-index: -3;

    }
    .img-phone {
      position: absolute;
      z-index: -2;
      margin-left: 80px;
      margin-top: -390px;
      width: 170px;
      height: 320px;
    }

    .in-flex-box2 {

      flex: 1;
      min-width: 200px;
    }

    .in-flex-box1 {
      flex: 1;
      min-width: 200px;
      z-index: 0;
      padding-left: 0px;
      height: 255px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }


    .title{
      margin-top: 50px;
      color: themed(color-price);
      font-size: 25px!important;
      font-weight: bolder;
      max-width: 250px;

    }

    .subtitle {
      margin-top: 25px;
      font-size: 15px;
      max-width: 220px;

    }


    //SLIDER
    .wrapper-phone{
      z-index: -1;
      width: 185px!important;
      display: flex;
      margin-top: -20px;
      margin-left: 100px;
      min-height: 380px;
      background-color: white;
      border-radius: 30px!important;
    }

    .img-case {
      margin-top: -48px;
      margin-left: -51px;
      z-index: 10;
      height: 380px;
    }

    .img-ContenentPhone {
      margin-top: 10px;
      margin-left: 9px;
      z-index: 200;
      height: 359px;
      border-radius: 0px !important;
    }


  }

  @media screen and (max-width: 300px){
    .container-banner {
      height: 450px;
    }
  }






}