@import "../../../styles/styles.scss";

@include themify(true) {

  // container //

  .section1 {
    margin-top: 55px;
    align-items: center;
    margin-left: 200px;
    width: 100%;
    flex: 1;
    max-width: 575px;
    transition: all 0.3s;
    text-align: justify;
  }

  .section2 {
    position: inherit;
    right: 150px;
    width: 100%;
    flex: 1;
    max-width: 575px;
    transition: all 0.3s;
    text-align: justify;
  }

  .section3 {
    align-items: center;
    width: 100%;
    flex: 1;
    margin-left: 200px;
    max-width: 575px;
    transition: all 0.3s;
    text-align: justify;
  }

  .flexSteps {
    position: relative;
    flex: 1;
    min-width: 200px;
    margin-top: 70px;
  }

  .flexSteps:first-child {
    flex: 1;
    text-align: -webkit-center;


  }

  .flexSteps div {
    padding: 2px;
    max-width: 480px;
    //background-color: #3df;
  }


 /* .section1:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    transform: scale(1.03);
  }

  .section2:hover {

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    transform: scale(1.03);

  }
  .section3:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    transform: scale(1.03);
  }
*/

  // Style //
  .title-steps {

    color: themed(regularText);
    margin-top: -19px;
    font-size: 25px;
    font-weight: bolder;
    font-family: "Montserrat", sans-serif;
  }
  .text-steps {
    font-size: 15px;

  }

  .link-mna {
    text-decoration-line: underline;
    color: themed(yellow_color);
    font-weight: 600;
  }


  //IMG
  .iconStepOne {

    width: 69px;
    height: 81px;
    z-index: 1;

  }

  .iconStepTwo {
    width: 89px;
    height: 63px;
    z-index: 1;
  }

  .iconStepThree {
    width: 70px;
    height: 80px;
    z-index: 1;
  }
  .imageThread {
    height: 374px;
    padding-left: 20%;
    margin-top: 63px;
    position: absolute;

  }

  @media only screen and (min-width: 1850px) and (max-width: 2000px) {
    .imageThread {
      height: 374px;
      padding-left: 28vw;

    }

  }


  /////QUERY PHONE/////
  @media screen and (max-width: 767px) {


    .flexSteps div {
      padding: 5px;
      max-width: 380px;

    }

    .section1 {

      margin-top: 55px;
      align-items: center;
      margin-left: 0px !important;
      width: 100%;
      flex: 1;
      max-width: 575px;
      transition: all 0.3s;
      text-align: justify;
    }

    .section2 {
      align-items: center;
      position: inherit;
      right: 0px !important;
      width: 100%;
      flex: 1;
      max-width: 575px;
      transition: all 0.3s;
      text-align: justify;
    }

    .section3 {
      align-items: center;
      width: 100%;
      flex: 1;
      margin-left: 0px !important;
      max-width: 575px;
      transition: all 0.3s;
      text-align: justify;
    }


    //BOX 1
    .container-steps {
      margin-top: -30px;
    }


    .title-steps {
      color: themed(regularText);

      font-size: 25px;

      font-weight: bolder;
      font-family: "Montserrat", sans-serif;
      min-width: 200px;
    }

    .text-steps {
      font-size: 12px;
    }


    .section-shadow {
      width: 100%;
    }

    .imageThread {
      height: 42vh;
      width: 25vw;
      //left: 250px;
      margin-top: 63px;
      position: absolute;
      display: none;

    }

    .section1 {
      margin-top: 55px;
      align-items: center;
      //margin-left: 200px;
      width: 70%;
      flex: 1;
      max-width: 575px;
      transition: all 0.3s;
      text-align: justify;
    }

    .section2 {

      align-items: center;
      width: 70%;
      flex: 1;
      max-width: 575px;
      transition: all 0.3s;
      text-align: justify;
    }

    .section3 {
      align-items: center;
      width: 70%;
      flex: 1;
      //margin-left: 200px;
      max-width: 575px;
      transition: all 0.3s;
      text-align: justify;
    }


    //IMG
    .iconStepOne {

      width: 49px;
      height: 61px;
      z-index: 1;

    }

    .iconStepTwo {
      width: 69px;
      height: 43px;
      z-index: 1;
    }

    .iconStepThree {
      width: 50px;
      height: 60px;
      z-index: 1;
    }


  }


  /////QUERY TABLET//////
  @media (min-width: 768px) and (max-width: 1024px) {


    .title-steps {
      font-size: 18px;
      min-width: 300px;

    }

    .text-steps {
      font-size: 17px;
      max-width: 310px;
    }


    .iconStepOne {
      width: 37px;
      height: 37px;
      z-index: 1;

    }

    .iconStepTwo {
      width: 37px;
      height: 37px;
      z-index: 1;
    }

    .iconStepThree {
      width: 37px;
      height: 37px;
      z-index: 1;
    }

    .text-steps {
      font-size: 10px;
    }

    .flexSteps div {
      padding: 13px;
    }
  }

    @media screen and (max-width: 900px) {
      .imageThread {
        display: none;

      }

      .section1 {
        margin-top: 55px;
        align-items: center;
        margin-left: 0px !important;
        width: 100%;
        flex: 1;
        max-width: 575px;
        transition: all 0.3s;
        text-align: justify;
      }

      .section2 {
        align-items: center;
        position: inherit;
        right: 0px !important;
        width: 100%;
        flex: 1;
        max-width: 575px;
        transition: all 0.3s;
        text-align: justify;
      }

      .section3 {
        align-items: center;
        width: 100%;
        flex: 1;
        margin-left: 0px !important;
        max-width: 575px;
        transition: all 0.3s;
        text-align: justify;
      }


    }


    @media screen and (max-width: 1207px) {
      .imageThread {
        height: 374px;
        padding-left: 100px !important;
        margin-top: 70px;
        position: absolute;

      }
      .text-steps{
        font-size: 13px;
      }

    }

  }

