$color-gray: #707070;
$error-color: #DB1212;
$warning-color: #F7C756;
$info-color: #63DDED;
$success-color: #4FD186;
$color-primary: #7B5DF6;
$text-color: #7B5DF4;

.no-error-message {
  visibility: hidden;
  height: 0;
  max-width: 574px;
  text-align: start;
}

.error-message {
  font-size: 11px;
  width: 100%;
  color: red;
  max-width: 574px;
  text-align: start;
  height: 3px;
}

.notification {
  position: fixed;
  top: 80px;
  z-index: 300000;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 425px;
  min-height: 57px;
  box-shadow: 0 0 10px rgba(142, 147, 159, 0.15);
  border-radius: 57px;
  height: 57px;
  width: 425px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px solid;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 16px;
  animation: slide-in 0.5s forwards;

  &.error {
    background-color: rgba($error-color, 0.15);
    border-color: $error-color;
  }

  &.warning {
    background-color: rgba($warning-color, 0.15);
    border-color: $warning-color;
  }

  &.info {
    background-color: rgba($info-color, 0.15);
    border-color: $info-color;
  }

  &.success {
    background-color: rgba($success-color, 0.15);
    border-color: $success-color;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.nag-status-container {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nag-status {
  background-color: transparent;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  color: white;

  &.error {
    background-color: $error-color;
  }

  &.warning {
    background-color: $warning-color;
  }

  &.info {
    background-color: $info-color;
  }

  &.success {
    background-color: $success-color;
  }
}

.close {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.step-main-container {
  display: flex;
  padding: 0 0 158px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;

  .step-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100%;

    .step-content {
      padding: 0 32%;

      * {
        text-align: center;
      }

      .step-title {
        font-size: 25px;
        padding: 37px 0 30px 0;
      }

      .step-subtitle {
        font-size: 15px;
      }
    }

    .verify-form {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      margin-top: 25px;

      .code-digits {
        width: 100%;
        max-width: 528px;
        height: 43px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;

        // Eliminar los selectores de numero del campo de tipo number
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type=number] {
          -moz-appearance: textfield;
        }

        .digit-field {
          width: 100%;
          max-width: 574px;
          padding: 10px;
          outline: none;
          border: 0;

          background: #EFEFEF 0% 0% no-repeat padding-box;
          border-radius: 10px;
          opacity: 1;

          text-align: center;
          font-size: 15px;
        }
      }

      .resent-code {
        text-decoration: underline;
        letter-spacing: 0px;
        color: $text-color;
        opacity: 1;
        font-size: 19px;
        margin-top: 60px;
        cursor: pointer;
      }

      .resent-code-disabled{
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      }

      .resend-counter{
        letter-spacing: 0;
        color: #000000;
        opacity: 1;
        margin-top: 30px;
      }
    }

    .step-buttons {
      position: fixed;
      bottom: 46px;
      width: 45vw;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .button {
        padding: 6px 76px;
        border-radius: 20px;
        border: none;
        cursor: pointer;

        &.back {
          background-color: white;

          &:hover {
            background-color: darken(white, 10%);
          }

          &:focus {
            background-color: darken(white, 10%);
          }

          &:active {
            background-color: lighten(white, 5%);
          }
        }

        &.next {
          background-color: $warning-color;

          &:hover {
            background-color: darken($warning-color, 5%);
          }

          &:focus {
            background-color: darken($warning-color, 5%);
          }

          &:active {
            background-color: lighten($warning-color, 5%);
          }
        }
      }
    }
  }
}