* {
  padding: 0px;
  margin: 0px;

}

.header-container {
  display: none;
}

.cloud {
  position: relative;
  top: 0;
  left: 0;
  width: 90%;
  height: 300px;
  z-index: -1;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 300px;
}

.box {
  width: 120px;
  height: 120px;
  text-align: center;
  margin-left: 5%;
  margin-top: 40px;
}


.language-box {
  margin-top: 50px;

  width: 200px;
  height: 60px;


}

.language-box label {
  margin-left: 40px;
  margin-top: 20px;
}

.main-title-container {
  margin-top: 150px;
  height: 300px;
}

.icon-start {
  width: 80px;
  margin-left: 10px;
}

.title-container {
  width: 100%;
  max-width: 900px;
  height: 230px;
  margin: 20px auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.main-title-container div h1 {
  font-size: 40px;
  text-align: center;
  letter-spacing: 0;
  color: #EA40A6;
  opacity: 1;
}

#pop {
  font-size: 15px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-weight: bold;
  padding: 4px 6px;
  margin-top: -4px;
}

.return {
  padding: 4px 6px;
  margin-top: -4px;
  z-index: 1000;
  font-size: 15px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-weight: bold;
}

#language {
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
}


#logo {
  position: absolute;
}

#home {
  cursor: pointer;
}

.container_header {
  width: 100%;
  min-height: 72px;
  align-content: end;
}

.background_container {
  position: absolute;
  height: 300px;
  width: 100%;
}

.items-container {
  position: relative;
  z-index: 1;
  padding: 0 73px;
  display: flex;
}

.logo-pop {
  width: 114px;
}

.logo-pop img {
  width: 100%;
}

.title-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title-page {
  font-size: 40px;
  text-align: center;
  color: #EA40A6;
  opacity: 1;
}

.title-header-container {
  width: 100%;
  /*max-width: 900px;*/
  display: flex;
  padding: 40px 30px 0;
  justify-content: center;
}

.icon-start-container {
  margin-left: 10px;
}

.buttons-header-container {
  width: 100%;
  max-width: 200px;
  font-size: 15px;
  display: flex;
  height: 25px;
  justify-content: space-around;
}

#homw {
  cursor: pointer;
  font-size: 20px;
}

.title-response {
  display: none;
}

.lang-icon{
  height: 20px;
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.lang-icon:hover{
  transform: scale(1.2);
  transition: all 200ms ease;
  filter: invert(38%) sepia(78%) saturate(2100%) hue-rotate(220deg) brightness(95%) contrast(120%);
}
@media screen and (max-width: 1200px) {
  .items-container {
    padding: 20px 60px;
  }
  .title-page {
    font-size: 35px;
  }
}

@media screen and (max-width: 900px) {
  .items-container {
    padding: 20px 40px;
  }
  .title-page {
    font-size: 35px;
  }
  .title-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 50px 0;
  }
  .title-header-container {
    width: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: center;
  }
  .buttons-header-container {
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    display: flex;
    height: 25px;
    justify-content: space-around;
  }
}

@media screen and (max-width: 600px) {
  .title-buttons-container {
    width: calc(100% - 100px);
    display: flex;
    margin: 0 auto;
    align-items: flex-end;
  }

  .title-header-container {
    display: none;
  }

  .items-container {
    height: max-content;
    padding: 20px 40px;
    display: flex;
    align-items: center;
  }

  .title-response {
    display: flex;
    padding: 0 40px;
  }

  .container-title-responsive {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    justify-content: center;
  }

  .buttons-header-container {
    width: 100%;
    max-width: 200px;
    font-size: 15px;
    display: flex;
    height: 25px;
    justify-content: space-around;
  }

  #pop {
    margin-right: 15px;
  }
  .icon-start-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  .logo-pop {
    width: 30%;
  }

  .title-page {
    font-size: 30px;
    width: 100%;
  }
}