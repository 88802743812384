@import "../../../styles/styles.scss";

@include themify(true) {

  #btn-more{
    display: none;
  }
  .container-btn{
    position: fixed;
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
    right: 40px;
    z-index: 100;
    width: 35px;
  }
  .networks a, .icon-mas2{

    text-decoration: none;
    color: themed(background);
    width: 50px;
    max-width: 50px;
    height: 50px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding-left:25px;
    border-radius: 30px;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.4);
    transition: all 300ms ease;

    span {
     // min-width: 100px;
      //margin-right: -73px;
    }
  }

  .container-btn:hover {
    width: 100px;
    .networks a, .icon-mas2 {
      width: 150px;
      max-width: 150px;
      padding-left: 0;

      span {
        margin-right: 0;
      }
    }
  }

  .networks a:hover{
    background: themed(background);
    color: themed(whatsapp-color);
  }
  .networks a{
    margin-bottom: -15px;
    opacity: 0;
    visibility: hidden;
  }
  #btn-mas:checked~ .networks a{
    margin-bottom: 20px;
    opacity: 1;
    visibility: visible;
  }
  .icon-mas2{

    background:themed(whatsapp-color);
    font-size: 11.5px;
  }


  .btn-more {
    display: flex;
    align-items: center;
  }
  .wa-icon {
    margin-top: -1px;
    margin-left: 27px;
    //margin-left: 3px;
    margin-right: 6px;
    height: 35px;
  }

  .style-link{
    text-decoration: none;
    color: themed(background);
  }

  @media screen and (max-width: 767px) {
    .networks a, .icon-mas2{
      width: 45px;
      max-width: 45px;
      height: 45px;
    }

  }


  .container-btn:hover {
    width: 150px;
    .networks a, .icon-mas2 {
      width: 150px!important;
      max-width: 150px!important;
      padding-left: 0;

      span {
        margin-right: 0px;
      }
    }
  }

}
